@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Istok+Web&family=Oswald&display=swap');

body {
  margin: 0;
  font-family: 'Istok Web', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-container.dark {
  background-color: #1F1F1F;
  color: #909090;
}

.main-container {
  background-color: #e8e8e8;
  color: #414141;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dark h1,
.dark h2,
.dark h3,
.dark h4,
.dark h5,
.dark h6 {
  font-family: 'Oswald', sans-serif;
  color: #BEBEBE;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Oswald', sans-serif;
  color: #414141;
}

.dark a {
  color: #BEBEBE;
}

a {
  text-decoration: none;
  color: #414141;
}

.dark p, 
.dark small {
  color: #909090;
}

p, small {
  color: #414141;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 28px;
}

svg {
  display: inline-block
}

.project img,
.profile-picture {
  -webkit-filter: grayscale(80%); /* Safari 6.0 - 9.0 */
  filter: grayscale(80%);
}


.project img:hover {
  cursor: pointer;
}

.modal img {
  -webkit-filter: grayscale(80%); /* Safari 6.0 - 9.0 */
  filter: grayscale(80%);
}

.project .desc {
  background: rgb(31,31,31, 0.8);
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  cursor: pointer;
}

.project:hover .desc {
  opacity: 1;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.dark #footer {
  background-image: -webkit-linear-gradient(top, rgba(31, 31, 31, 0) 0%, rgba(31, 31, 31, 0.6) 22%, rgba(31, 31, 31, 0.7) 36%, rgba(31, 31, 31, 0.9) 55%, #1F1F1F 76%);
  background-image: -moz-linear-gradient(top, rgba(31, 31, 31, 0) 0%, rgba(31, 31, 31, 0.6) 22%, rgba(31, 31, 31, 0.7) 36%, rgba(31, 31, 31, 0.9) 55%, #1F1F1F 76%);
  background-image: -o-linear-gradient(top, rgba(31, 31, 31, 0) 0%, rgba(31, 31, 31, 0.6) 22%, rgba(31, 31, 31, 0.7) 36%, rgba(31, 31, 31, 0.9) 55%, #1F1F1F 76%);
  background-image: linear-gradient(-180deg, rgba(31, 31, 31, 0) 0%, rgba(31, 31, 31, 0.6) 22%, rgba(31, 31, 31, 0.7) 36%, rgba(31, 31, 31, 0.9) 55%, #1F1F1F 76%);
}

.footer #footer {
  background-image: -webkit-linear-gradient(top, rgba(232, 232, 232, 0) 0%, rgba(232, 232, 232, 0.6) 32%, rgba(232, 232, 232, 0.7) 38%, rgba(232, 232, 232, 0.9) 60%, #E8E8E8 76%);
  background-image: -moz-linear-gradient(top, rgba(232, 232, 232, 0) 0%, rgba(232, 232, 232, 0.6) 32%, rgba(232, 232, 232, 0.7) 38%, rgba(232, 232, 232, 0.9) 60%, #E8E8E8 76%);
  background-image: -o-linear-gradient(top, rgba(232, 232, 232, 0) 0%, rgba(232, 232, 232, 0.6) 32%, rgba(232, 232, 232, 0.7) 38%, rgba(232, 232, 232, 0.9) 60%, #E8E8E8 76%);
  background-image: linear-gradient(180deg, rgba(232, 232, 232, 0) 0%, rgba(232, 232, 232, 0.6) 32%, rgba(232, 232, 232, 0.7) 38%, rgba(232, 232, 232, 0.9) 60%, #E8E8E8 76%);
}

#footer {
  height: 100px;
  /* width: -webkit-calc(100% - 32px);
  width: -moz-calc(100% - 32px);
  width: calc(100% - 32px); */
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -moz-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  padding-bottom: 20px;
}